const _ = require('lodash');
const Base = require('./base');
const Dev = require('./dev');
const Prod = require('./prod');
const PreProd = require('./demo');

let actualConfig = Dev;

if ("production" === 'production') {
    actualConfig = Prod;
} else if (
  "production" === 'pre-production' ||
  "production" === 'demo'
) {
    actualConfig = PreProd;
}

const merged = _.merge({}, Base, actualConfig);
module.exports = merged;
